<template>
    <el-row :gutter="15">
        <el-col :span="8">
            <div v-loading="$wait.is('loading')" class="card text-center" element-loading-spinner="el-custom-spinner">
                <p class="text-lg mb-4 font-semibold">
                    {{ totalHours }}
                </p>
                <p>
                    {{ $t('employees.total_worked_hours') }}
                </p>
            </div>
        </el-col>
        <el-col :span="8">
            <div v-loading="$wait.is('loading')" class="card text-center" element-loading-spinner="el-custom-spinner">
                <p class="text-lg mb-4 font-semibold">
                    {{ totalPlannedHours }}
                </p>
                <p>
                    {{ $t('employees.total_planned_hours') }}
                </p>
            </div>
        </el-col>
        <el-col :span="8">
            <div v-loading="$wait.is('loading')" class="card text-center" element-loading-spinner="el-custom-spinner">
                <p class="text-lg mb-4 font-semibold">
                    {{ averageRating }}
                </p>
                <p>
                    {{ $t('employees.average_rate') }}
                </p>
            </div>
        </el-col>
    </el-row>
</template>
<script>
import sumBy from 'lodash/sumBy';
import meanBy from 'lodash/meanBy';

export default {
    props: {
        historyData: {
            type:    Array,
            default: () => [],
        },
    },

    computed: {
        totalHours() {
            return this.normalizeTime(sumBy(this.historyData, 'duration'));
        },

        totalPlannedHours() {
            return this.normalizeTime(sumBy(this.historyData, 'planned'));
        },

        averageRating() {
            const realRatings = this.historyData.filter(object => object.rate && object.rate.rate > 0);

            const average = meanBy(realRatings, 'rate.rate').toFixed(1);

            return realRatings.length > 0 ? average : 0;
        },
    },
};
</script>
